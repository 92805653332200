<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadLandContracts(true)" />
                </div>
            </div>
            <el-table :data="landContracts.data" class="mt-2">
                <el-table-column label="名称/编号">
                    <template #default="scope">
                        <div>
                            <router-link :to="'/land-contract/detail?id='+scope.row.id" target="_blank">
                                {{scope.row.name}}
                            </router-link>
                        </div>
                        <div class="text-info">{{scope.row.code}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="partnerName" label="土地来源方" sortable />
                <el-table-column prop="contractArea" label="合同面积(亩)" sortable width="140" />
                <el-table-column prop="contractAmount" label="合同金额(元)" sortable width="140" />
                <el-table-column label="承包日期" width="140">
                    <template #default="scope">
                        <div>{{$moment.ld(scope.row.beginContractDate)}}</div>
                        <div>{{$moment.ld(scope.row.endContractDate)}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="160">
                    <template #default="scope">
                        <el-button type="text" @click="showSignLandContractOfflineDialog(scope.row)">线下签约</el-button>
                        <el-button type="text" @click="showSignLandContractOnlineDialog(scope.row)">线上签约</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="landContracts.totalRecords"
                :current-page="landContractsPagination.currentPage" :page-size="landContractsPagination.pageSize"
                class="mt-3" @current-change="landContractsPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="signLandContractOfflineDialogVisible" title="线下签约" :close-on-click-modal="false"
            width="640px">
            <div>
                <div>线下签约合同附件</div>
                <div class="border rounded mt-2 p-1">
                    <multi-file-uploader v-model="landContract.signOfflineContractAttachments"
                        accept=".pdf,.jpg,.jpeg,.png" />
                </div>
                <div class="text-info mt-2">上传已线下签章生效的PDF、图片附件。</div>
            </div>
            <template #footer>
                <el-button type="primary" @click="signLandContractOffline">确定</el-button>
                <el-button @click="signLandContractOfflineDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <el-dialog :visible.sync="signLandContractOnlineDialogVisible" title="线上签约" :close-on-click-modal="false"
            width="640px">
            <el-form>
                <el-form-item label="土地来源方">
                    <el-input v-model="landContract.partnerName" disabled placeholder="土地来源方" />
                </el-form-item>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="土地来源方合同签署人姓名" class="form-item-required">
                            <el-input v-model="landContract.partnerSignerName" placeholder="土地来源方合同签署人姓名" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="土地来源方合同签署人手机" class="form-item-required">
                            <el-input v-model="landContract.partnerSignerMobile" placeholder="土地来源方合同签署人手机" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="水发公司（乙方）">
                    <el-input v-model="landContract.companyName" disabled placeholder="水发公司（乙方）" />
                </el-form-item>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="水发合同签署人姓名" class="form-item-required">
                            <el-input v-model="landContract.companySignerName" placeholder="水发合同签署人姓名" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="水发合同签署人手机" class="form-item-required">
                            <el-input v-model="landContract.companySignerMobile" placeholder="水发合同签署人手机" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="mt-2">其他签署人</div>
                <div class="bg-light rounded mt-2 p-3">
                    <div v-for="(otherSignatory,index) in landContract.otherSignatories" :key="index">
                        {{otherSignatory.receiverName}}
                        （{{otherSignatory.receiverMobile}}）
                        {{otherSignatory.companyName}}
                    </div>
                    <el-empty v-if="landContract.otherSignatories.length==0" />
                </div>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="signLandContractOnline">确定</el-button>
                <el-button @click="signLandContractOnlineDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                key: null,
                landContracts: {},
                landContractsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                landContract: {
                    otherSignatories: [],
                },
                signLandContractOfflineDialogVisible: false,
                signLandContractOnlineDialogVisible: false,
            };
        },
        methods: {
            async loadLandContracts(resetPage = false) {
                if (resetPage) {
                    this.landContractsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/LandContract/GetLandContracts', {
                    params: {
                        companyId: this.$store.state.companyId,
                        status: 3,
                        key: this.key,
                        pageIndex: this.landContractsPagination.currentPage - 1,
                        pageSize: this.landContractsPagination.pageSize,
                    }
                });
                this.landContracts = response.data;
            },
            landContractsPaginationCurrentChange(page) {
                this.landContractsPagination.currentPage = page;
                this.loadLandContracts();
            },
            showSignLandContractOfflineDialog(landContract) {
                this.landContract = JSON.parse(JSON.stringify(landContract));
                this.signLandContractOfflineDialogVisible = true;
            },
            showSignLandContractOnlineDialog(landContract) {
                this.landContract = JSON.parse(JSON.stringify(landContract));
                this.signLandContractOnlineDialogVisible = true;
            },
            async signLandContractOffline() {
                if (!confirm('确定要签约吗？')) {
                    return;
                }

                await this.$axios.post('/api/LandContract/SignLandContractOffline', {
                    id: this.landContract.id,
                    signOfflineContractAttachments: this.landContract.signOfflineContractAttachments,
                });
                this.signLandContractOfflineDialogVisible = false;
                this.loadLandContracts();
            },
            async signLandContractOnline() {
                if (!confirm('确定要签约吗？')) {
                    return;
                }

                await this.$axios.post('/api/LandContract/SignLandContractOnline', {
                    id: this.landContract.id,
                    partnerSignerName: this.landContract.partnerSignerName,
                    partnerSignerMobile: this.landContract.partnerSignerMobile,
                    companySignerName: this.landContract.companySignerName,
                    companySignerMobile: this.landContract.companySignerMobile,
                });
                this.signLandContractOnlineDialogVisible = false;
                this.loadLandContracts();
            },
        },
        created() {
            this.loadLandContracts();
        },
    };
</script>